<template>
  <div v-if="load && contenido1">
      <div class="custom-template">
        <div class="d-flex p-2 background-title">
          <div class="font-weight-bold text-danger">
            <p @click="showOptionContyngency" style="cursor: pointer;">
              Fuera de linea: {{ fecha }} Hora: {{hora}}
            </p>
          </div>
        </div>
        <div class="d-flex p-2 flex-column content">
          <div class="text-level">
            <p> Nivel {{ contenido1.cod_clasificador }}: {{ contenido1.descripcion }} </p>
          </div>
        </div>
      </div>
    <contingency
      ref="modalContingencia"
      :tipoContingencias="tipoContingencias"
      @selectMotivoContingencia="selectMotivoContingencia"
    />
  </div>
</template>

<script>
import moment from 'moment';
import Contingency from './modalContingency.vue';

export default {
  name: 'notificacionContingency',
  props: {
    contenido: {
      type: Object,
      default: null,
    },
    tipoContingencias: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      fecha: moment().format('DD/MM/YYYY'),
      hora: moment().format('HH:mm:ss'),
      contenido1: null,
      load: false,
    };
  },
  watch: {
    contenido(new1) {
      this.contenido1 = new1;
      if (new1 != null) {
        this.showAlert();
      }
    },
  },
  methods: {
    showAlert() {
      this.fecha = moment().format('DD/MM/YYYY');
      this.hora = moment().format('HH:mm:ss');
      this.load = true;
    },
    showOptionContyngency() {
      this.$bvModal.show('modal-contingency');
      this.$refs.modalContingencia.tipoContingenciaSelected = (
        this.tipoContingencias.find((el) => el.cod_clasificador === this.contenido1.cod_clasificador));
    },
    selectMotivoContingencia(data) {
      this.contenido1 = data;
      this.$bvModal.hide('modal-contingency');
      this.$emit('_selectMotivoContingencia', data);
    },
  },
  components: {
    Contingency,
  },
};
</script>
<style scoped lang="scss">
.custom-template {
  text-align: left;
  font-size: 12px;
  margin: 0px 5px;
  &, & > div {
    box-sizing: border-box;
  }
  border: 1px solid #ffefc5;
}
.background-title {
  background-color: #fef4d1
}
.content {
  background-color: #fff9e8;
  .text-level {
    color: #9f8034
  }
  .text-siat {
    color: black
  }
}
</style>
