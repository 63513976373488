<template>
  <div>
    <b-modal id="modal-guardar-qr" size="lg"
    hide-footer
    :visible="visible"
    @change="$emit('change', $event)">
      <template #modal-header="{ close }">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-8">
                <strong>QR Controlado </strong>
            </div>
            <div class="col-sm-4 text-right">
              <button class="btn  col-sm-2 px-0 py-1 btn-secondary mr-2">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-printer"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                <path
                  d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2
                  2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2
                  2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2
                  2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2
                  2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1
                  1 0 0 1 1 1z"
                />
              </svg>
              </button>
              <span class="font-weight-bold align-middle" @click="close()"
                style="cursor: pointer">X</span>
            </div>
          </div>
        </div>
      </template>
      <div class="row px-3">
        <div class="col-sm-4">
          <img :src="require('@/assets/images/faces/QRcode.png')"
            style="border-radius: 12px 0 0 12px;position: relative;"
            class="no-selectable"
            width="100%">
        </div>
        <div class="col-sm-8">
          <div class="form-group" style="text-align: center;">
            <span style="color: red;">Tiempo Restante: 10:01</span>
          </div>
          <div class="row mb-2 bg-gray">
            <div class="form-group row" style="padding: 7px;">
              <label for="importe" class="col-sm-4 col-form-label text-right">
                Importe:
              </label>
              <div class="col-sm-8">
                <input type="text" id="talla" class="form-control">
              </div>
            </div>
          </div>
          <div class="row mb-1 bg-gray">
            <div class="form-group row" style="padding: 7px;">
              <label for="celular" class="col-sm-2 col-form-label text-right">
                Celular
              </label>
              <div class="col-sm-3">
                <input type="text" class="form-control">
              </div>
              <div class="col-sm-4">
                <input type="text" class="form-control">
              </div>
              <div class="col-sm-1">
                <button type="button" class="btn btn-success">Enviar</button>
              </div>
              <span style="font-size: 11px; margin: auto;">
                Se enviara por Whatsapp la imagen del QR
              </span>
            </div>
          </div>
          <div class="row mb-1">
            <div class="form-group row" style="margin: auto;">
              <div class="col-sm-2">
                <input type="checkbox">
              </div>
              <span style="font-size: 10px;">
                Enviar por Whatsapp la factura
            </span>
            </div>
          </div>
          <div class="row">
            <button class="btn btn-blue mr-2" type="button">Generar otro</button>
            <button class="btn btn-warning mr-2" type="button">Ver Estado</button>
            <button class="btn btn-danger" type="button">Cancelar</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'theMoldalQR',
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.bg-gray {
  background: #eee;
}
.btn-blue {
  background: rgb(104, 104, 174);
  color: white;
}
</style>
